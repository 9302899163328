import _ from "lodash";
import React, { Component } from "react";
import { Words } from "./Words";

export default class Form extends Component {
  state = {
    inputValue: "",
    todos: [],
    words: Words(),
  };

  inputChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  search = (pattern) => {
    var p2 = "^" + pattern.replace(/\*/g,".*").replace(/\?/g,".") + "$"
    var prex = new RegExp(p2,"i")
    var rr = _.filter(this.state.words, (o) => {
        return prex.test(o);
    })
    return rr;
  }

  buttonSubmit = (e) => {
    e.preventDefault();
    if (this.state.inputValue !== "") {
      this.setState({
        todos: this.search(this.state.inputValue)
      });
    }
  };

  render() {
    return (
      <form onSubmit={this.buttonSubmit}>
        <input
          type="text"
          value={this.state.inputValue}
          placeholder="Tipar (Pattern)..."
          onChange={this.inputChange}
        />
        <button onClick={this.buttonSubmit}>Cauta</button>
        <ol>
          {this.state.todos.map((todo, index) => (
            <li key={index}>
              <a href={`https://www.dex.ro/${todo}`} target="_blank">{todo}</a>
            </li>
          ))}
        </ol>
      </form>
    );
  }
}
