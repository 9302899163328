import React from "react";
import Form from "./components/Form";


export function App() {
  return (
    <div className="App">
      <h1>Cautare Dictionar</h1>
      <Form />
    </div>
  );
}